
























































import useCompanyMode from "@/use/companyMode";
import useUser from "@/use/user";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MContactUserSelect: () =>
      import("@/components/molecules/m-contact-user-select.vue"),
    MContactUsersSelect: () =>
      import("@/components/molecules/m-contact-users-select.vue"),
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  setup(_, { root }) {
    const form = ref<any>(null);
    const user = computed(() => root.$store.getters["user/getData"]);
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { isCompanyModeEnabled } = useCompanyMode({ root });

    const state = reactive({
      valid: true,
      isLoading: false,
    });

    const data = reactive({
      types: [
        { value: "single", text: "Do jednego użytkownika" },
        { value: "bulk", text: "Do wielu użytkowników na raz" },
      ],
      roles: computed(() => {
        const roles = [];

        if (hasAccessTo.value("customer"))
          roles.push({ value: "driver", text: "Kierowcy" });
        if (hasAccessTo.value("employee"))
          roles.push({ value: "customer", text: "Klienci" });
        if (hasAccessTo.value("admin"))
          roles.push({
            value: "employee",
            text: "Pracownicy",
            disabled: isCompanyModeEnabled.value,
          });

        return roles;
      }),
    });

    const model = reactive({
      type: (root.$route.query.type as string) || "single",
      to: (root.$route.query.to as string) || "driver",
      user: (root.$route.query.user as string) || "",
      message: (root.$route.query.message as string) || "",
      company: null as any,
    });

    const rules = {
      user: [
        (v: string[]) =>
          !!v || "Wybierz użytkownika, do którego chcesz napisać",
      ],
      message: [(v: string) => !!v || "Podaj treść wiadomości"],
      to: [
        (v: string) =>
          !!v || "Wybierz użytkowników, do których chcesz wysłać wiadomość",
      ],
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.valid) {
        if (model.type === "single") {
          root.$router.push({
            name: "panel.contact.service",
            params: { id: model.user },
          });
        } else if (model.type === "bulk") {
          const axiosInstance = root.$store.getters[
            "api/getInstance"
          ] as AxiosInstance;

          state.isLoading = true;

          const data = {
            message: model.message,
            to: model.to,
            company: model.company ? model.company.id : undefined,
          };

          axiosInstance
            .post("contact/bulk", data)
            .then(() => root.$router.push({ name: "panel.contact.list" }))
            .catch((error) => console.log(error))
            .finally(() => (state.isLoading = false));
        }
      }
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      form,
      user,
      state,
      data,
      model,
      rules,
      onSubmit,
    };
  },
});
